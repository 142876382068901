import { isVanfCleefProcess } from "helpers/constants";
import { UserPermissions } from "models/User.interface";
import React, { createContext, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { getUser } from "store/application/ApplicationActions";

export interface PermissionsList {
  hasOtherAuctionsPermission: boolean;
  hasDisplaySerialNumberPermission: boolean;
  hasMarketPlacePermission: boolean;
}

interface AuthContextProps {
  user: User | null;
  updateUser: (userData: User | null) => void;
  isAdmin: () => boolean;
  hasPermissions: (permissions: Array<UserPermissions>) => boolean;
  //isAuthenticated: boolean;
  //login: (userData: User) => void;
  //logout: () => void;
}

interface User {
  email: string;
  id: string;
  permissions: string[];
  specificPermissions?: PermissionsList;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

const hasPermissionsAccess = (permissions: Array<UserPermissions>, user: User): boolean =>
  (user?.permissions && permissions.every(value => user?.permissions.indexOf(value) !== -1)) ?? false;

export const AuthProvider: React.FC<any> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const dispatch = useDispatch();


  const isAdmin = (): boolean => (isAuthenticated && user?.permissions?.includes(UserPermissions.ADMIN)) ?? false;

  const hasPermissions = (permissions: Array<UserPermissions>): boolean => hasPermissionsAccess(permissions, user);

  const updateUser = (userData: User | null) => {
    const hasVintagePermission = hasPermissionsAccess([UserPermissions.VINTAGE], userData)
    const hideOtherAuctions = isVanfCleefProcess();
    const hasDisplaySerialNumberPermission = hasPermissionsAccess([UserPermissions.ADMIN], userData) || isVanfCleefProcess();

    const permissions: PermissionsList = {
      hasOtherAuctionsPermission: !hideOtherAuctions,
      hasDisplaySerialNumberPermission,
      hasMarketPlacePermission: !hasVintagePermission
    }
    setIsAuthenticated(!!userData);
    const newData = {
      ...userData,
      specificPermissions: permissions
    }
    setUser(newData);
    dispatch(getUser(newData)); // TODO use only store at the end
    // TODO logout = setUser(null);
  }


  return (
    <AuthContext.Provider value={{ user, updateUser, isAdmin, hasPermissions }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};