import {
  APPLICATION_IS_READY,
  HIDE_NOTIFICATION,
  SHOW_NOTIFICATION,
  DATA_IS_LOADING, 
  TOKEN_FORGOT_PASSWORD,
  GET_USER
} from "./ApplicationActions";

const initialState = {
  isReady: false,
  isLoadingData: {},
  notification: {
    messageType: null,
    message: null,
    isNotified: false,
  },
  user: {
    email: undefined,
    id: undefined,
    permissions: [],
    specificPermissions: {
      hasOtherAuctionsPermission: false,
      hasDisplaySerialNumberPermission: false,
    }
  }
};

const application = (state = initialState, action = {}) => {
  switch (action.type) {
    case DATA_IS_LOADING:
      return {
        ...state,
        isLoadingData: {
          ...state.isLoadingData,
          [action.prefix]: action.isLoading,
        },
      };
    case APPLICATION_IS_READY:
      return {
        ...state,
        isReady: action.isReady,
        themeName: action.themeName
      };
    case TOKEN_FORGOT_PASSWORD:
      return {
        ...state,
        emailForgotPassword: action.emailForgotPassword
      };
    case HIDE_NOTIFICATION:
      return {
        ...state,
        notification: {
          isNotified: false,
          message: null,
          messageType: null,
        },
      };
    case SHOW_NOTIFICATION:
      return {
        ...state,
        notification: {
          isNotified: true,
          message: action.payload.message,
          messageType: action.payload.messageType,
        },
      };
    case GET_USER:
      return {
        ...state,
        user: {
          ...action.user,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default application;
