import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ForgotPasswordToken from "../components/forgot-password/forgot-password-token/ForgotPasswordToken";
import { themeNameSelector } from "../store/application/ApplicationSelectors";
import { emailForgotPasswordSelector } from "../store/application/ApplicationSelectors";
import { emailForgotPassword } from "../store/application/ApplicationActions";
import { useNotificationValues } from "../components/notification/NotificationHooks";
import Notification from "../components/notification/Notification";

import { getLogo } from "../helpers/constants";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(emailForgotPassword(null));
  }, [dispatch]);

  const themeName = useSelector(themeNameSelector);
  const logo = getLogo(themeName);
  const { isNotified, notificationMessage, messageType } = useNotificationValues();
  const email = useSelector(emailForgotPasswordSelector);

  const handleChangeEmail = () => {
    dispatch(emailForgotPassword(null));
  };


  return (
    <div className="login-wrapper">
      <Notification
        open={isNotified}
        message={notificationMessage}
        messageType={messageType}
      />
      <div className="login-logo">
        <img src={logo} className="App-logo" alt="logo" />
      </div>
      {!email && (
        <ForgotPasswordToken />
        )}
      {email && (
        <div>
          <p>An email has been sent to: <strong>{email}</strong>.</p>
          <p>Click the link in this email to reset your password</p>
          <br />
          <p style={{ fontSize: "14px" }}>Please <span style={{ color: "#c00000" }}>check in your spam folder</span> if you didn't receive anything.</p>
          <br />
          <p style={{ fontSize: "14px" }}>Wrong email? <span className="wrong-email" onClick={handleChangeEmail}>Re-enter your email</span>
          </p>
          <br />
          <br />
        </div>
      )}
      <br />
        <Link className="forgot-password" to="/login">Back To Login</Link>
    </div>
  );
};

export default ForgotPassword;



