import { Button } from "@material-ui/core";
import React from "react";
//import { exportExcel } from "helpers/utils";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FilterListIcon from "@material-ui/icons/FilterList";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import Pagination from "@material-ui/lab/Pagination";
import MainCurrencyButton from "components/shared/form/MainCurrencyButton/MainCurrencyButton";
import { formatDate } from "helpers/utils";
import { withRouter } from "react-router-dom";
import { getSortByArray } from "utils/filters.utils";
import { useCategoryValues } from "./CategoryHooks";
import SearchPanel from "./SearchPanel/searchPanel";
import "./category.scss";
import { ProductItem } from "./productItem/ProductItem";
import { isVanfCleefProcess } from "helpers/constants";

const Category: React.FC<any> = () => {
  const {
    productsList,
    totalPages,
    sortby,
    isFilterOpen,
    searchFormData,
    printProducts,
    handleSortby,
    openFilters,
    closeFilters,
    handleChangePage,
    clearSearchForm,
    getKeywords,
    handleSpecificPagination,
    errorClassSpecificPage,
  } = useCategoryValues();
  const isVanCleef = isVanfCleefProcess();

  const keywords = getKeywords();

  const showExtractCsvButton = productsList?.user?.permissions?.some((i: any) =>
    [ "admin", "superuser"].includes(i)
    );
  

  const addMonths = (date_str: any, nb_months: number) => {
    const date = new Date(date_str);
    return new Date(date.setMonth(date.getMonth() + nb_months));
  };

  

  return (
    <div className={`page-category ${isFilterOpen ? "is-filter-open" : ""}`}>
      <div className="page-category__filters-overlay"></div>
      <div className="page-category__filters">
        <SearchPanel
          onCloseFilters={closeFilters}
          clearSearchForm={clearSearchForm}
          initialValues={searchFormData}
          isFilterOpen={isFilterOpen}
        />
      </div>
      <div className="page-category__results">
        <div className="vca-container-page page-category__results__buttons">
          <div
            className="page-category__results__buttons__open-filter"
            onClick={openFilters}
          >
            <span className="filter-text">Filters</span>
            <span className="filter-icon">
              <FilterListIcon />
            </span>
          </div>
          <div className="secondary-filter-header page-category__results__buttons__right">
            <div className="page-category__results__buttons__right__info">
              <div className="title">
                Showing: <span className="fw-bold">{productsList?.count?.toLocaleString('en-US')}</span>
              </div>
              { (productsList?.user_subscription && (
                <div>
                  <div className="chip-container">
                    <div className="title">Requests completed: </div>
                    <span className="fw-bold">{-(productsList.user_subscription?.current_number ?? 0)}</span>
                  </div>
                  <div className="chip-container">
                    <div className="title">Subscription: </div>
                    <span className="fw-bold">Unlimited</span>
                    (Active until {formatDate(productsList.user_subscription?.end_date)})
                  </div>
                </div>
              )) || (
                productsList?.user_requests && (
                  <div className="chip-container">
                    <div className="title">Requests left: </div>
                    <span className="fw-bold">{productsList.user_requests?.current_number}</span>
                    until {formatDate(addMonths(productsList.user_requests?.last_refresh, 1))}
                  </div>
                )
              )
              }
              {keywords?.length > 0 && (
                <div className="chip-container">
                  <div className="title">Your Search: </div>
                  <div className="chip-container__chips">
                    {keywords.map((keyword, index) => (
                      <Chip size="small" label={keyword} key={index} />
                    ))}
                  </div>
                </div>
              )}
            </div>
            <MainCurrencyButton requestProductList />
            <div className="sort-by-btn">
              <div className="title">Sort by</div>
              <div className="sort-by-btn__select">
                <div className="sort-by-btn__select__icon">
                  <UnfoldMoreIcon />
                </div>
                <Select
                  value={
                    searchFormData.sort_by ? searchFormData.sort_by : sortby
                  }
                  onChange={handleSortby}
                  displayEmpty
                  className=""
                  inputProps={{ "aria-label": "sort by" }}
                >
                  {getSortByArray(isVanCleef).map((sort) => (
                    <MenuItem value={sort.value} key={sort.value}>
                      {sort.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <div className="wrap-btn right btns-right">
              {/* <Button
              className="vca-btn vca-btn--secondary"
              variant="outlined"
              onClick={() => history.push(`/create-product`)}>
              {"Add +"}
            </Button> */}
            {showExtractCsvButton && (
              <Button
                className="vca-btn vca-btn--secondary vca-btn--large"
                variant="outlined"
                onClick={printProducts}
              >
                {"Extract CSV"}
              </Button>
            )}
              <Button
                className="vca-btn vca-btn--large"
                variant="contained"
                onClick={window.print}
              >
                {"Print"}
              </Button>
            </div>
          </div>
        </div>
        {productsList?.count > 0 && (
          <div className="vca-container-page page-category__results__result">
            {productsList?.data.map((product: any) => (
              <div className="page-category__item" key={product?.id}>
                <ProductItem product={product} />
              </div>
            ))}
          </div>
        )}
        {(productsList?.error || productsList?.count === 0) && (
          <div className="vca-container-page page-category__results__noresult">
            {productsList?.error ? productsList.error : "No Results"}
          </div>
        )}
        {productsList?.total_pages > 1 && (
          <div className="page-category__results__pagination">
            <Pagination
              count={totalPages}
              page={productsList?.page}
              showFirstButton
              showLastButton
              onChange={handleChangePage}
            />
            <form onSubmit={handleSpecificPagination} className="pagination-spe">
              <div className="vca-textfield">
                <div className="vca-textfield__field">
                  <input type="number" name="paginationText" className={`small ${errorClassSpecificPage}`}/>
                </div>
              </div>
              <button className="vca-btn" type="submit"><NavigateNextIcon /></button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(Category);
