import React, { Fragment } from "react";
import {
  CssBaseline,
} from "@material-ui/core";
import { useLayout } from "layout/LayoutHook";
import './layout.scss';
import { themeNameSelector } from "../store/application/ApplicationSelectors";
import { getLogo, isVanfCleefProcess } from "../helpers/constants"
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useAuth } from "providers/AuthContext";

const Layout: React.FC<any> = ({ children }) => {
  const { handleLogout } = useLayout(); // TODO  gérer le logout 
  const { isAdmin } = useAuth();
  const isVanCleef = isVanfCleefProcess();
  const themeName = useSelector(themeNameSelector);
  const logo = getLogo(themeName)

  return (
    <Fragment>
      <CssBaseline />
      <header className="main-header vca-container-page">
        <div className="main-header__wrap">
          <div className="main-header__logo">
            <NavLink to="/category" >
              <img src={logo} className="App-logo" alt="logo" />
            </NavLink>
          </div>
          <nav className="main-header__menu">
            {/* <div className="main-header__menu__item">
              <NavLink to="/category" className="item-link" activeClassName="is-active">
                <IconButton component="span" size="small">
                  <Search />
                </IconButton>
              </NavLink>
            </div> */}
            {/* <div className="main-header__menu__item">
              <span>Paul Montfort</span>
            </div> */}
            {/* <div className="main-header__menu__item">
              <NavLink to="/category" className="item-link" activeClassName="is-active">
                Find jewels
              </NavLink>
            </div> */}
            {isAdmin() && (
              <div className="main-header__menu__item">
                <NavLink to="/duplicatas" className="item-link" activeClassName="is-active">
                  Duplicatas
                </NavLink>
              </div>
            )}
            {!isVanCleef && (
              <div className="main-header__menu__item">
                <a href="https://database.jewelsforeternity.com/subscription/" className="d-block vca-btn vca-btn--rounded vca-btn--third" style={{padding: '7px 20px'}}>
                  Subscription
                </a>
              </div>
            )}
            {!isVanCleef && (
              <div className="main-header__menu__item">
                <NavLink to="/terms" className="item-link" activeClassName="is-active">
                  Terms
                </NavLink>
              </div>
            )}
            <div className="main-header__menu__item border-left">
              <span onClick={handleLogout} className="item-link">
                Logout
              </span>
            </div>
          </nav>
        </div>
      </header>
      <main className="main-content">
        {children}
      </main>
    </Fragment>
  );
};

export default Layout;
