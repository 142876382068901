import { Button, FormControl, Grid } from "@material-ui/core";
import HTTPClient from "httpClient/dataClient";
import { useAuth } from "providers/AuthContext";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getLogo, isVanfCleefProcess } from "../../helpers/constants";
import { ERROR, showNotification } from "../../store/application/ApplicationActions";
import { themeNameSelector } from "../../store/application/ApplicationSelectors";
import Notification from "../notification/Notification";
import { useNotificationValues } from "../notification/NotificationHooks";
import RenderTextField from "../shared/form/RenderTextField";
import { emailPatternValidation } from "../shared/validated-form/LoginValidation";
import { loginStyles } from "./LoginStyles";
import "./login.scss";

const Login: React.FC = () => {
  const { updateUser } = useAuth();
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
  });
  const isVanCleef = isVanfCleefProcess();
  const classes = loginStyles();
  const { isNotified, notificationMessage, messageType } = useNotificationValues();
  const themeName = useSelector(themeNameSelector);
  const logo = getLogo(themeName);
  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmit = async (values: any) => {
    try {
      const response = await HTTPClient.createClient().post(`/login`, {
        email: values.username,
        password: values.password,
      });

      if (response.data.user) {
        // TODO remove when logic of login is done
        localStorage.setItem(
          "vanCleef_app_token",
          response.data?.token
        );
        console.log(response.data.user)
        updateUser({
          email: response.data.user.email,
          id: response.data.user.id,
          permissions: response.data.user.permissions
        });
        history.push("/category");
      } else {
        dispatch(showNotification({
          payload: {
            messageType: ERROR,
            message: "Invalid email or password",
          },
        }));
      }

      // Redirection vers la page '/category'
    } catch (error) {
      // handle error
      dispatch(showNotification({
        payload: {
          messageType: ERROR,
          message: "An error occured",
        },
      }));
    }
  };

  return (
    <div className="login-wrapper">
      <div className="login-logo">
        <img src={logo} className="App-logo" alt="logo" />
      </div>
      <Notification
        open={isNotified}
        message={notificationMessage}
        messageType={messageType}
      />
      <form className="vca-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="vca-form-line">
          <FormControl>
            <RenderTextField
              placeholder="Email"
              variant="outlined"
              input={register('username', {
                required: 'Email is required',
                pattern: {
                  value: emailPatternValidation,
                  message: "Invalid email address"
                },
              })}
              error={!!errors.username}
              helperText={errors?.username?.message}
            />
          </FormControl>
        </div>
        <div className="vca-form-line">

          <RenderTextField
            placeholder="Password"
            type="password"
            autoComplete="current-password"
            variant="outlined"
            input={register('password', { required: "Password is required" })}
            error={!!errors.password}
            helperText={errors?.password?.message}
          />
        </div>
        <div className="vca-form-line">
          <Button
            className="vca-btn w-100pct"
            variant="contained"
            type="submit"
          >
            {"Login"}
          </Button>
        </div>
        <Grid item className={classes.item}>
          <Link className="forgot-password" to="/forgot-password">
            {"Create or reset password"}
          </Link>
        </Grid>
        {!isVanCleef && (
          <Grid item className="terms">
            <Link className="forgot-password" to="/terms">
              {"Terms"}
            </Link>
          </Grid>
        )}
      </form>
    </div>
  );
};

export default Login;
