import { Checkbox, FormControl, FormControlLabel } from "@material-ui/core";
import RenderCheckbox from "components/shared/form/RenderCheckbox";
import { MultiSelectRecord, FilterCheckboxListProps } from "models/Filters.interface";
import { SelectAllCheckboxProps } from "models/Forms/Forms.interface";
import { ProductStoreFilterCheckbox } from "models/Store/ProductStore.interface";
import React, { ChangeEvent, useCallback } from "react";
import { useSelector } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import AccordionWrapper from "../AccordionWrapper";
import FilterWrapper, { FilterWrapperBorderProps } from "../FilterWrapper";
import { useFilterCheckbox } from "./useFilterCheckbox";

type CheckboxComplexLists = Array<{
  title: string;
  checkboxList: MultiSelectRecord,
  storeValuePrefix: string;
  checkboxesStateChanges?: SelectAllCheckboxProps;
}>;

interface FilterCheckboxPropsSelectAll extends FilterWrapperBorderProps {
  checkboxList: MultiSelectRecord,
  checkboxListForSelectAll?: MultiSelectRecord, // in case we want to select all with subs
  checkboxComplexLists?: CheckboxComplexLists,
  storeValuePrefix: string;
  storeFormName: string;
  checkboxesStateChanges?: SelectAllCheckboxProps;
  labelSelectAll?: string;
  isDisabled?: boolean;
}

interface FilterCheckboxProps extends FilterCheckboxPropsSelectAll, FilterWrapperBorderProps {
  title: string;
  selectAll?: boolean;
  noWrapper?: boolean;
  isSubAccordion?: boolean
}


const FilterCheckbox: React.FC<FilterCheckboxProps> = (props: FilterCheckboxProps) => {

  const { borderBottom, noWrapper } = props;
  const CheckboxComponent = (
    <FilterCheckboxAccordion
      {...props} />
  );

  return noWrapper ? CheckboxComponent : (
    <FilterWrapper borderBottom={borderBottom}>
      {CheckboxComponent}
    </FilterWrapper>
  );
}

const FilterCheckboxAccordionSelectAll: React.FC<FilterCheckboxPropsSelectAll> = ({
  checkboxList,
  checkboxListForSelectAll,
  storeValuePrefix,
  checkboxesStateChanges,
  labelSelectAll,
  isDisabled,
  storeFormName
}) => {
  const { allChecked, handleSelectAll } = useFilterCheckbox({
    checkboxesStateChanges: checkboxesStateChanges,
    checkboxList: checkboxList,
    checkboxListForSelectAll: checkboxListForSelectAll,
    storeValuePrefix: storeValuePrefix + '.',
    storeFormName: storeFormName
  })

  return (
    <FormControl>
      <FormControlLabel
        labelPlacement="start"
        className="f-italic"
        control={
          <Checkbox
            onChange={(event: ChangeEvent<HTMLInputElement>) => handleSelectAll(event.target.checked)}
            checked={allChecked.isSelected}
            indeterminate={allChecked.isIndeterminate}
            disabled={isDisabled}
          />
        }
        label={labelSelectAll}
      />
    </FormControl>
  )
}


const FilterCheckboxAccordion: React.FC<FilterCheckboxProps> = ({
  title,
  checkboxList,
  checkboxListForSelectAll,
  checkboxComplexLists,
  storeValuePrefix,
  checkboxesStateChanges,
  selectAll,
  labelSelectAll = "Select All",
  isDisabled,
  storeFormName,
  isSubAccordion
}) => {

  const selector = formValueSelector(storeFormName);
  const storeValue = useSelector(state => selector(state, storeValuePrefix));

  const getAmountBoxChecked = useCallback((obj: ProductStoreFilterCheckbox) => {
    const list = checkboxListForSelectAll || checkboxList
    let filteredObjects;
    if(obj){
      filteredObjects = Object.fromEntries(Object.entries(obj).filter(
        ([key, value]) => {
          if (key in list && value === true) {
            return true
          }
          return false
        }
      ))
    }
    return obj && Object.keys(filteredObjects).length
  }, [storeValue]);

  return (
    <AccordionWrapper title={title} amountBoxChecked={getAmountBoxChecked(storeValue)} isSubAccordion={isSubAccordion}>
      <FormControl>
        {selectAll && (
          <FilterCheckboxAccordionSelectAll
            checkboxList={checkboxList}
            checkboxListForSelectAll={checkboxListForSelectAll}
            storeValuePrefix={storeValuePrefix}
            checkboxesStateChanges={checkboxesStateChanges}
            labelSelectAll={labelSelectAll}
            isDisabled={isDisabled}
            storeFormName={storeFormName}
          />
        )}
        {
          Object.values(checkboxList)
            .map((checkbox: FilterCheckboxListProps, index: number) => (
              <Field
                component={RenderCheckbox}
                name={`${storeValuePrefix}.${checkbox.paramName}`}
                label={checkbox.label}
                labelPlacement="start"
                key={index}
                isDisabled={isDisabled}
              />
            ))}

        <div>
          {checkboxComplexLists?.length > 0 && checkboxComplexLists.map(({
            title: titleSub,
            checkboxList: checkboxListSub,
            storeValuePrefix: storeValuePrefixSub,
            checkboxesStateChanges: checkboxesStateChangesSub }) => (
            <FilterCheckboxAccordion
              isSubAccordion
              key={titleSub}
              title={titleSub}
              selectAll={true}
              checkboxList={checkboxListSub}
              storeValuePrefix={storeValuePrefixSub}
              checkboxesStateChanges={checkboxesStateChangesSub}
              storeFormName={storeFormName} />
          ))}
        </div>
      </FormControl>
    </AccordionWrapper>
  )
}

export default FilterCheckbox;