export enum AuctionsNames {
  Antiquorum = "Antiquorum",
}

export const auctionsListNames: Array<string> = [
  "Christie's",
  "Sotheby's",
  "Bonhams",
  "Phillips",
  "Doyle",
  "Artcurial",
  "Tajan",
  AuctionsNames.Antiquorum,
];

export const otherAuctionsListNames: Array<string> = [
  "Aguttes",
  "Alexandre Landre",
  "Aponem",
  "Ader",
  "Artesia",
  "Art Richelieu",
  "Art Valorem",
  "Astrid Guillon",
  "Auction Art Rémy Le Fur & Associés",
  "Baron Ribeyre & Associés",
  "Beaussant Lefèvre & Associés",
  "Brissonneau",
  "Castor Hara",
  "Chayette & Cheval",
  "Christophe Joron Derem",
  "Copages Auction Paris",
  "Coutau-Bégarie",
  "Crait + Müller",
  "Daguerre",
  "De Baecque et Associés",
  "Deburaux - du Plessis",
  "Delon - Hoebanx",
  "Digard Auction",
  "Drouot Estimations",
  "Eric Caudron",
  "Euvrard & Fabre",
  "Eve",
  "Ferri & Associés",
  "Giquello",
  "Gros & Delettrez",
  "Icon Auction",
  "Jean-Marc Delvaux",
  "Jonquet",
  "Kahn & Associés",
  "Kâ-Mondo",
  "Lasseron & Associés",
  "Le Brech & Associés",
  "Leducq Maison de ventes aux enchères",
  "Le Floc'h",
  "Lemon Auction",
  "L'Huillier & Associés",
  "Magnin Wedry",
  "Maison R&C, Commissaires-Priseurs Associés",
  "Marie-Saint Germain",
  "Maurice Auction",
  "Morand & Morand",
  "Néo Enchères",
  "Nicolas Nouvelet Commissaire-priseur",
  "Nouvelle Etude",
  "Oger - Blanchet",
  "Paris Enchères - Collin du Bocage",
  "Pescheteau-Badin",
  "Pestel-Debord",
  "Phidias",
  "Steffen’s Enchères Rambouillet",
  "Thierry de Maigret",
  "Varenne Enchères",
  "Villemur-art",
  "Yann Le Mouel",
]
