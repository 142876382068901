import { MultiSelectRecord } from "models/Filters.interface";
import { createRecordFromNames } from "../ProductStore.utils";
import { AuctionsNames, auctionsListNames, otherAuctionsListNames } from "./AuctionsNames";

export const getAllAuctionsListRecord = (hasOtherAuctionPermission: boolean): MultiSelectRecord => (
  { ...getAuctionsListRecord(),
    ...(!hasOtherAuctionPermission ? {} : getOtherAuctionsListRecord())
  })

export const getAuctionsListRecord = (): MultiSelectRecord => 
  createRecordFromNames(auctionsListNames, [], [AuctionsNames.Antiquorum]);

export const getOtherAuctionsListRecord = (): MultiSelectRecord =>
  createRecordFromNames(otherAuctionsListNames, [], []);