interface SortBy {
  label: string;
  value: string;
  hideVca?: boolean;
}

const sortbyArray: Array<SortBy> = [
  { label: "Relevance", value: "-" },
  { label: "Date of sale (high to low)", value: "sort_by=date&order=desc&" },
  { label: "Date of sale (low to high)", value: "sort_by=date&order=asc&" },
  { label: "Price (high to low)", value: "sort_by=price&order=desc&" },
  { label: "Price (low to high)", value: "sort_by=price&order=asc&" },
  { label: "Price per carat (high to low)", value: "sort_by=price_per_carat&order=desc&", hideVca: true },
  { label: "Price per carat (low to high)", value: "sort_by=price_per_carat&order=asc&", hideVca: true },
];

export const getSortByArray = (isVca: boolean): Array<SortBy> => {
  if(!isVca) {
    return sortbyArray;
  }
  return sortbyArray.filter((item: SortBy) => !item.hideVca);
}

export const getDefaultSortBy = (isVanCleef?: boolean): string => isVanCleef ? sortbyArray[0].value : sortbyArray[1].value;