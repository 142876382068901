import { RequestResult } from './Dto.interface';
import { StoneDto } from './StonesDto.interface';

// API
export enum JewelStatusLabelDto {
  SOLD = "Sold",
  NOTSOLD = "Not sold",
  FORSALE = "For sale",
  WITHDRAWN = "Withdrawn",
}

// Label used in the view
export enum JewelStatusLabel {
  SOLD = "Sold",
  NOTSOLD = "Not Sold",
  FORSALE = "For Sale",
  WITHDRAWN = "Withdrawn",
}

export enum SaleType {
  LIVE = "Live",
  ONLINE = "Online",
}

export enum JewelSourceTypeLabelDto {
  MARKETPLACE = "Marketplace",
  AUCTION = "Auction",
}

export interface JewelDto {
  _sort_value_eur: any;
  _sort_value_usd: any;
  admin: any;
  city: string;
  cluster_hash: string;
  condition_report: string;
  created: Date;
  created_at: Date;
  creator: string;
  currency: string;
  current_selling_price: number;
  current_selling_price_eur: number,
  current_selling_price_usd: number,
  date_manufacture_history: Date;
  date_manufacture_max: Date;
  date_manufacture_min: Date;
  date_of_sale: Date;
  discount: number;
  ending_date: Date;
  estimate_high: number;
  estimate_high_eur: number;
  estimate_high_usd: number;
  estimate_low: number;
  estimate_low_eur: number;
  estimate_low_usd: number;
  estimated_retail_price: number;
  estimated_retail_price_eur: number;
  estimated_retail_price_usd: number;
  exhibited: string;
  has_video: boolean;
  highlight: string[];
  id: string;
  image: [
    {
      image_url: string;
      path: string;
      position: number
    }
  ];
  is_online: boolean;
  is_vca: boolean;
  literature: string;
  location: string;
  lot_category: string;
  lot_description: string;
  lot_essay: string;
  lot_number: string;
  lot_title: string;
  name: string;
  notes: string;
  notes_history: string;
  post_lot_text: string;
  pre_lot_text: string;
  price: number;
  price_eur: number,
  price_per_carat_eur: number;
  price_per_carat_usd: number;
  price_usd: number,
  previous_price: number,
  previous_price_eur: number,
  provenance: string;
  result_id: string;
  sale_room_notice: string;
  selling_prices: number;
  serial_number: string | [any];
  serial_number_history: number;
  sold_twice: any;
  source: string;
  source_type: JewelSourceTypeLabelDto;
  special_notice: string;
  starting_date: Date;
  status: JewelStatusLabelDto;
  stones: StoneDto[];
  url: string;
  url_lot: string;
  url_sale: string;
}

export type JewelResultsDto = RequestResult<JewelDto>;