import DateFnsUtils from "@date-io/date-fns";
import { Button } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import { ArrowBack, Close, Edit, Star, Stars } from "@material-ui/icons";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MainCurrencyButton from "components/shared/form/MainCurrencyButton/MainCurrencyButton";
import "date-fns";
import frLocale from "date-fns/locale/fr";
import { addHTMLLineBreak } from "helpers/text-utils";
import { JewelDto } from "models/Dto/JewelsDto.interface";
import { ProductStoreSearch } from "models/Store/ProductStore.interface";
import React, { useEffect, useRef, useState } from "react";
import { Carousel } from "react-carousel-minimal";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { createPriceBlocksInfoArray } from "utils/priceBlock.utils";
import noImage from "../../_assets/no-image-large.png";
import StringDefaulter from "../../helpers/StringDefaulter";
import { awsRepoImg, isVanfCleefProcess } from "../../helpers/constants";
import { searchFormDataSelector } from "../../store/product/ProductSelectors";
import RenderTextField from "../shared/form/RenderTextField";
import PriceBlock from "./PriceBlock/PriceBlock";
import { useViewProduct } from "./ViewProductHook";
import ViewProductModel from "./ViewProductModel";
import HistoryTooltip from "./history-tooltip/HistoryTooltip";
import StonesAdmin from "./ui/StonesAdmin";
import "./viewProduct.scss";
import ZoomImage from "./zoom-image/ZoomImage";
import { isString } from "lodash";

const ViewProduct: React.FC<any> = (props) => {
  const searchFormData: ProductStoreSearch = useSelector(searchFormDataSelector);
  const origin = window.location.origin;

  const [isCurrencyEur, setIsCurrencyEur] = useState<boolean>(true);

  const isVanCleef = isVanfCleefProcess();

  useEffect(() => {
    setIsCurrencyEur(!searchFormData.currency || searchFormData.currency === 'eur')
  }, [searchFormData]);
  const newProps = JSON.parse(JSON.stringify(props));

  const { productInfo, editIndividualField } = useViewProduct();

  const productInfoData: JewelDto =
    productInfo?.data?.length > 0 ? productInfo.data[0] : null;

  const history = useHistory();

  const [isOpen, setOpen] = useState(false);
  const [slideNumber, setSlideNumber] = useState(0);
  const [showEditIndividual, setShowEditIndividual] = useState(false);
  const [showEditDateOfManufacture, setShowEditDateOfManufacture] =
    useState(false);
  const [showEditProductNote, setShowEditProductNote] = useState(false);
  const [serialNumber, setSerialNumber] = useState("");
  const [dateOfManufactureMin, setDateOfManufactureMin] = useState(null);
  const [dateOfManufactureMax, setDateOfManufactureMax] = useState(null);
  const [productNote, setProductNote] = useState("");
  const [productNotePreview, setProductNotePreview] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const serialNumberLoaded = useRef(false);

  const minDateManufacture = new Date(1900, 0, 1);

  const body = document.querySelector("body");

  const hasEditIndividualPermission = productInfo?.user_permissions?.some((permission: string) =>
    ["all", "vca", "admin", "superuser"].includes(permission)
  );
  const hasEditDateOfManufacturePermission =
    productInfo?.user_permissions?.some((permission: string) =>
      ["all", "vca", "admin", "superuser"].includes(permission)
    );

  const validateYear = (date: Date) => date instanceof Date && !isNaN(date.getFullYear());

  // get current image for carousel... (not the best, the only thing I could see with this plugin though...)
  // We may have to change for a new plugin carousel in the futur

  const getCurrentSlideNumber = () => {
    if (!newProps.isMounted) return 0;

    const dots = newProps?.dots;
    let slideNum = 0;
    for (let i = 0; i < dots.length; i++) {
      if (dots[i].classList.contains("active")) break;
      slideNum++;
    }
    setSlideNumber(slideNum);
  };

  const openImageFullScreen = () => {
    if (!newProps.isMounted) return;
    setOpen(true);
    getCurrentSlideNumber();
    body.classList.add("stop-scroll");
  };
  const closeImageFullScreen = () => {
    if (!newProps.isMounted) return;
    setOpen(false);
    getCurrentSlideNumber();
    body.classList.remove("stop-scroll");
  };

  const sendLotByEmail = () => {
    //printPdf(); // TODO Later
    const formattedBody = `\n\n Link to lot: ${productInfoData?.url_lot}`;
    console.log(encodeURIComponent(formattedBody));
    window.location.href = `mailto:?body=${encodeURIComponent(formattedBody)}`;
    //window.location.href = `mailto:mail@example.org?subject=Email Subject&body=${formattedBody}`;
  };

  history.listen(() => {
    body.classList.remove("stop-scroll");
  });

  // only after the initial render thus replicating `componentDidMount` lifecycle behaviour
  useEffect(() => {
    newProps.isMounted = true;
    newProps.imageFullScreen = document.querySelector(
      ".carousel-image-full-screen"
    );
    newProps.dots = document.querySelectorAll(".carousel-container .dots .dot");
    if (productInfoData?.serial_number && !serialNumberLoaded.current && isString(productInfoData.serial_number)) {
      setSerialNumber(productInfoData.serial_number);
      serialNumberLoaded.current = true;
    }
  }, [newProps, productInfoData?.serial_number]);

  useEffect(() => {
    setIsLoading(false);
  }, [productInfoData]);

  useEffect(() => {
    setProductNote(productInfoData?.notes);
    setProductNotePreview(productInfoData?.notes);
  }, [productInfoData?.notes]);

  const isMarketplace = () => {
    return productInfoData?.source_type === "Marketplace";
  };

  const productFactory = new ViewProductModel(productInfoData, isCurrencyEur, isVanCleef);
  const productInfoDataAdminStone = productFactory.mapPostprocessingStone()
  const infoProduct = productFactory.createInfoProductList();
  const carouselData = productFactory.createCarouselData();

  const adminDebugData = productInfoData?.admin
    ? productInfoData?.admin.map((element: any, index: number) => (
      <li key={index}>
        <b>{element[0]}</b>: {element[1]}
      </li>
    ))
    : [];

  const handlePostprocess = (event: any) => {
    event.preventDefault();
    editIndividualField({ postprocess: 1 });
  };

  const handleDoChatGPT = (event: any) => {
    event.preventDefault();
    editIndividualField({ do_chatgpt: 1 });
  };

  const handleDoImgTransfer = (event: any) => {
    event.preventDefault();
    editIndividualField({ do_imgtransfer: 1 });
  };


  const handleSubmitSerialNumber = (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    editIndividualField({ serial_number: serialNumber });
  };

  const handleSubmitDateOfManufacture = (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    setShowEditDateOfManufacture(false);
    editIndividualField(
      {
        date_manufacture_min: dateOfManufactureMin?.getFullYear() || null,
        date_manufacture_max: dateOfManufactureMax?.getFullYear() || null
      }
    );
  };

  const handleSubmitNotes = (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    editIndividualField({ notes: productNotePreview }).then(_ => {
      setShowEditProductNote(false)
    });
  };

  return (
    <div className="page-view-category vca-container-page">
      <div className="secondary-filter-header page-view-category__buttons">
        <div className="wrap-btn">
          <Button
            className="vca-btn vca-btn--secondary no-border"
            onClick={() => history.push(`/category`)}
            startIcon={<ArrowBack />}
          >
            <span className="hide-xs-max">{"Search Results"}</span>
          </Button>
        </div>
        {productInfoData && (
          <div className="wrap-btn right">
            {/* <Button
            className="vca-btn vca-btn--secondary"
            variant="outlined"
            onClick={() => history.push(`/edit-product/${productId}`)}
          >
            {"Edit"}
          </Button> */}
            <MainCurrencyButton />
            {productInfoData?.admin && <Button
              className="vca-btn vca-btn vca-btn--secondary vca-btn--large"
              variant="contained"
              onClick={handleDoImgTransfer}
            >
              {"🔁 images"}
            </Button>
            }
            {productInfoData?.admin && <Button
              className="vca-btn vca-btn vca-btn--secondary vca-btn--large"
              variant="contained"
              onClick={handleDoChatGPT}
            >
              {"🔁 stones"}
            </Button>
            }
            {productInfoData?.admin && <Button
              className="vca-btn vca-btn vca-btn--secondary vca-btn--large"
              variant="contained"
              onClick={handlePostprocess}
            >
              {"🔁 postprocess"}
            </Button>
            }
            <Button
              className="vca-btn vca-btn vca-btn--secondary vca-btn--large"
              variant="contained"
              onClick={sendLotByEmail}
            >
              {"Send"}
            </Button>
            <Button
              className="vca-btn vca-btn--large hide-s-max"
              variant="contained"
              onClick={window.print}
            >
              {"Print"}
            </Button>
          </div>
        )}
      </div>
      {productInfoData && (
        <div>
          <div className="page-view-category__content">
            <div
              className={`page-view-category__content__carousel ${productFactory.imageNumber <= 1 ? "one-image" : ""
                }`}
            >
              {productFactory.hasImage && (
                <div
                  className="btn-view-image-fullscreen"
                  onClick={openImageFullScreen}
                >
                  <ZoomOutMapIcon />
                </div>
              )}
              {productFactory.hasImage && (
                <Carousel
                  data={carouselData}
                  time={5000}
                  width="650px"
                  height="350px"
                  captionStyle={{
                    fontSize: "2em",
                    fontWeight: "bold",
                  }}
                  slideNumber={false}
                  captionPosition="bottom"
                  automatic={false}
                  dots={true}
                  pauseIconColor="white"
                  pauseIconSize="40px"
                  slideBackgroundColor="white"
                  slideImageFit="contain"
                  thumbnails={true}
                  style={{
                    textAlign: "center",
                    maxWidth: "850px",
                    maxHeight: "500px",
                  }}
                />
              )}
              {!productFactory.hasImage && (
                <div className="page-view-category__content__carousel__no-image">
                  <img className="no-image" src={noImage} alt="illustration" />
                </div>
              )}
            </div>
            <div className="page-view-category__content__info">
              <div className="page-view-category__content__info__block">
                <h1 className="heading-1 title">
                  {productInfoData?.lot_title}
                  {productInfoData?.sold_twice?.length > 0 && (
                    <div className="tooltip">
                      <div className="tooltip__hovered">
                        Creation sold twice (or more)
                      </div>
                      <Stars style={{ fontSize: 25 }} />
                    </div>
                  )}
                </h1>
              </div>
                  
              {createPriceBlocksInfoArray(productInfoData, isCurrencyEur).map((info, index) => (
                <PriceBlock
                  key={index}
                  {...info}
                />
              ))}
              
              <div className="page-view-category__content__info__block">
                <div className="paragraph">
                  {StringDefaulter.defaultEmpty(productInfoData?.source)},{" "}
                  {isMarketplace() && productInfoData?.creator
                    ? StringDefaulter.defaultEmpty(productInfoData?.creator)
                    : StringDefaulter.defaultEmpty(productInfoData?.name)}
                </div>
                {!isMarketplace() && (
                  <div className="paragraph">
                    {StringDefaulter.defaultEmpty(productInfoData?.location)},{" "}
                    {productFactory.displayedDate}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="page-view-category__info">
            {infoProduct?.map((product, index) => {
              if (
                !product?.text &&
                product?.label !== "Individual" &&
                product?.label !== "Date Of Manufacture" &&
                product?.label !== "Stones" &&
                product?.label !== "Notes" &&
                product?.label !== "Auction History"
              ) {
                return false;
              } else {
                return (
                  <div
                    className={`page-view-category__info__line ${product?.isLong ? "long-content" : ""
                      } ${product?.isUrl ? "is-url" : ""}`}
                    key={index}
                  >
                    <div className="page-view-category__info__line__label">
                      <div className="paragraph">{product?.label}</div>
                    </div>
                    {!product?.isUrl &&
                      product.isLong &&
                      product.label !== "Stones" &&
                      product?.label !== "Notes" &&
                      product?.label !== "Auction History" &&
                      product.label !== "Individual" && (
                        <div className="page-view-category__info__line__content">
                          <div
                            className="paragraph"
                            dangerouslySetInnerHTML={{ __html: product?.text }}
                          ></div>
                        </div>
                      )}
                    {!product?.isUrl &&
                      !product.isLong &&
                      product?.label !== "Notes" &&
                      product?.label !== "Individual" &&
                      product?.label !== "Date Of Manufacture" &&
                      product.label !== "Stones" && (
                        <div className="page-view-category__info__line__content">
                          <div className="paragraph">{product?.text}</div>
                        </div>
                      )}
                    {product?.isUrl && (
                      <div className="page-view-category__info__line__content">
                        <a
                          href={product?.text}
                          target="_blank"
                          rel="noreferrer"
                          className="paragraph"
                        >
                          {product?.text}
                        </a>
                      </div>
                    )}
                    {product.label === "Individual" && (
                      <div className="page-view-category__info__line__content">
                        <div className="view-product-row-edit paragraph">
                          {!showEditIndividual && (
                            <div className="view-product-row-edit__info">
                              {productInfoData?.serial_number_history && (
                                <Star className="view-product-row-edit__info__edited" />
                              )}
                              <div className="view-product-row-edit__number">
                                {productInfoData?.serial_number ||
                                  `No Serial Number`}
                              </div>
                              {hasEditIndividualPermission && (
                                <div
                                  className="circle-icon cursor-pointer"
                                  onClick={() => !isLoading && setShowEditIndividual(true)}
                                >
                                  {isLoading ? <CircularProgress size={16} className="loader-icon" /> : <Edit />}
                                </div>
                              )}
                              {hasEditIndividualPermission &&
                                productInfoData?.serial_number_history && (
                                  <HistoryTooltip
                                    title="Individual Number History"
                                    history={
                                      productInfoData.serial_number_history
                                    }
                                  />
                                )}
                            </div>
                          )}
                          {showEditIndividual && (
                            <div className="view-product-row-edit__form">
                              <form onSubmit={handleSubmitSerialNumber}>
                                <RenderTextField
                                  name="title"
                                  variant="outlined"
                                  size="small"
                                  className="textfield-small"
                                  value={serialNumber}
                                  disabled={isLoading}
                                  onChange={(e: any) =>
                                    setSerialNumber(e.target.value)
                                  }
                                />
                                <Button
                                  className="vca-btn vca-btn--small"
                                  variant="outlined"
                                  type="submit"
                                  disabled={
                                    !serialNumber || serialNumber ===
                                    productInfoData.serial_number
                                    || isLoading
                                  }
                                >
                                  {"Save"}
                                </Button>
                              </form>
                              <div
                                className="circle-icon cursor-pointer"
                                onClick={() => !isLoading && setShowEditIndividual(false)}
                              >
                                {isLoading ? <CircularProgress size={16} className="loader-icon" /> : <Close />}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {product.label === "Date Of Manufacture" && (
                      <div className="page-view-category__info__line__content">
                        <div className="view-product-row-edit paragraph">
                          {!showEditDateOfManufacture && (
                            <div className="view-product-row-edit__info">
                              {productInfoData?.date_manufacture_history && (
                                <Star className="view-product-row-edit__info__edited" />
                              )}
                              <div className="view-product-row-edit__number">
                                {(!productInfoData?.date_manufacture_min && !productInfoData?.date_manufacture_max) && (
                                  `No Date Of Manufacture`
                                )}
                                {productInfoData?.date_manufacture_min && productInfoData?.date_manufacture_min === productInfoData?.date_manufacture_max ?
                                  (`${productInfoData?.date_manufacture_min}`) :
                                  !productInfoData?.date_manufacture_min && !productInfoData?.date_manufacture_max ? `` :
                                    (
                                      `${productInfoData?.date_manufacture_min && (`Between ${productInfoData?.date_manufacture_min} `)}
                                     ${productInfoData?.date_manufacture_max && (`and ${productInfoData?.date_manufacture_max} `)}
                                    `
                                    )
                                }
                              </div>
                              {hasEditDateOfManufacturePermission && (
                                <div
                                  className="circle-icon cursor-pointer"
                                  onClick={() =>
                                    !isLoading && setShowEditDateOfManufacture(true)
                                  }
                                >
                                  {isLoading ? <CircularProgress size={16} className="loader-icon" /> : <Edit />}

                                </div>
                              )}
                              {hasEditDateOfManufacturePermission &&
                                productInfoData?.date_manufacture_history && (
                                  <HistoryTooltip
                                    title="Date Of Manufacture History"
                                    history={
                                      productInfoData.date_manufacture_history
                                    }
                                  />
                                )}
                            </div>
                          )}
                          {showEditDateOfManufacture && (
                            <div className="view-product-row-edit__form form-year">
                              <form onSubmit={handleSubmitDateOfManufacture}>
                                <div
                                  className="vca-textfield"
                                  style={{ maxWidth: "120px" }}
                                >
                                  <div className="vca-textfield__label">
                                    Between
                                  </div>
                                  <div className="vca-textfield__field is-datepicker-component">
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                      locale={frLocale}
                                    >
                                      <KeyboardDatePicker
                                        autoOk
                                        name="date_manufacture_min"
                                        variant="inline"
                                        size="small"
                                        className="textfield-small"
                                        value={dateOfManufactureMin}
                                        onChange={(e) =>
                                          setDateOfManufactureMin(e)
                                        }
                                        minDate={minDateManufacture}
                                        maxDate={
                                          dateOfManufactureMax || new Date()
                                        }
                                        views={["year"]}
                                        placeholder="yyyy"
                                        format="yyyy"
                                      />
                                    </MuiPickersUtilsProvider>
                                  </div>
                                </div>
                                <div
                                  className="vca-textfield"
                                  style={{ maxWidth: "120px" }}
                                >
                                  <div className="vca-textfield__label">and</div>
                                  <div className="vca-textfield__field is-datepicker-component">
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                      locale={frLocale}
                                    >
                                      <KeyboardDatePicker
                                        autoOk
                                        name="date_manufacture_max"
                                        variant="inline"
                                        size="small"
                                        className="textfield-small"
                                        value={dateOfManufactureMax}
                                        onChange={(e) =>
                                          setDateOfManufactureMax(e)
                                        }
                                        minDate={
                                          dateOfManufactureMin ||
                                          minDateManufacture
                                        }
                                        maxDate={new Date()}
                                        views={["year"]}
                                        placeholder="yyyy"
                                        format="yyyy"
                                        disabled={!dateOfManufactureMin || isLoading}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </div>
                                </div>
                                <Button
                                  className="vca-btn vca-btn--small"
                                  variant="outlined"
                                  type="submit"
                                  disabled={(!dateOfManufactureMin) ||
                                    (dateOfManufactureMin?.getFullYear() === productInfoData?.date_manufacture_min && dateOfManufactureMax?.getFullYear() === productInfoData?.date_manufacture_max) ||
                                    (dateOfManufactureMin?.getFullYear() > dateOfManufactureMax?.getFullYear()) ||
                                    (dateOfManufactureMin && !validateYear(dateOfManufactureMin)) ||
                                    (dateOfManufactureMax && !validateYear(dateOfManufactureMax)) ||
                                    isLoading
                                  }
                                >
                                  {"Save"}
                                </Button>
                              </form>
                              <div
                                className="circle-icon cursor-pointer"
                                onClick={() =>
                                  !isLoading && setShowEditDateOfManufacture(false)
                                }
                              >
                                {isLoading ? <CircularProgress size={16} className="loader-icon" /> : <Close />}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {productInfoData?.admin
                      && product.label === "Stones"
                      && (
                        <StonesAdmin stonesInfo={productInfoDataAdminStone} />
                      )
                    }

                    {product.label === "Notes" && (
                      <div className={`page-view-category__info__line__content content-product-notes ${productNote ? 'has-notes' : ''}`}>
                        {productNote && !showEditProductNote && (
                          <div className="view-product-row-textarea">
                            <div className="view-product-row-edit paragraph">
                              <p dangerouslySetInnerHTML={{ __html: addHTMLLineBreak(productNote) }}></p>
                            </div>
                          </div>
                        )}
                        {showEditProductNote && (
                          <div className="view-product-row-edit edit-note-form">
                            <form onSubmit={handleSubmitNotes}>
                              <RenderTextField
                                name="title"
                                variant="outlined"
                                size="small"
                                className="textfield-small"
                                multiline
                                rows={6}
                                value={productNotePreview}
                                disabled={isLoading}
                                onChange={(e: any) =>
                                  setProductNotePreview(e.target.value)
                                }
                              />
                              <div className="edit-note-form-save">
                                <Button
                                  className="vca-btn vca-btn--small"
                                  variant="outlined"
                                  type="submit"
                                  disabled={
                                    !productNotePreview ||
                                    productNotePreview === productInfoData?.notes ||
                                    isLoading
                                  }
                                >
                                  {isLoading ? <CircularProgress size={16} className="loader-icon" /> : "Save"}
                                </Button>
                                {!isLoading && (
                                  <div
                                    className="circle-icon cursor-pointer"
                                    onClick={() => !isLoading && setShowEditProductNote(false)}
                                  >
                                    {isLoading ? <CircularProgress size={16} className="loader-icon" /> : <Close />}
                                  </div>
                                )}
                              </div>
                            </form>
                          </div>
                        )}
                        {productInfoData?.notes_history && (
                          <div className="individual-history-info">
                            <div>
                              Created By <span className="name">{productInfoData?.notes_history[0][2]}</span>,{" "}
                              <span className="date">
                                {new Date(productInfoData?.notes_history[0][0]).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                })}
                              </span>
                              {" "}at{" "}
                              <span className="date">
                                {new Date(productInfoData?.notes_history[0][0]).toLocaleString("en-US", {
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: false,
                                })}
                              </span>
                            </div>
                            <div>
                              Last Modified By <span className="name">{productInfoData?.notes_history[productInfoData?.notes_history.length - 1][2]}</span>,{" "}
                              <span className="date">
                                {new Date(productInfoData?.notes_history[productInfoData?.notes_history.length - 1][0]).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                })}
                              </span>
                              {" "}at{" "}
                              <span className="date">
                                {new Date(productInfoData?.notes_history[productInfoData?.notes_history.length - 1][0]).toLocaleString("en-US", {
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: false,
                                })}
                              </span>
                            </div>
                          </div>
                        )}
                        {!showEditProductNote && (
                          <div className={`edit-button cursor-pointer`}
                            onClick={() => !isLoading && setShowEditProductNote(true)}
                          >
                            <div
                              className="circle-icon"
                            >
                              {isLoading ? <CircularProgress size={16} className="loader-icon" /> : <Edit />}
                            </div>
                            <span className="edit-button__text">Edit</span>
                          </div>
                        )}
                      </div>
                    )}

                    {product.label === "Auction History" && productInfoData?.sold_twice?.length > 0 && (
                      <div className="page-view-category__info__line__content" >
                        {
                          productInfoData.sold_twice?.map((sale: any, index: number) => (
                            <div key={index} className="other-sales">
                              <p className="paragraph">{sale.source}, {sale.location}, {StringDefaulter.defaultDate(sale.date_of_sale)}, Lot {sale.lot_number}</p>
                              <p className="paragraph link">
                                URL lot: <Link to={`/view-product/${sale._id}`}>
                                  {`${origin}/view-product/${sale._id}`}
                                </Link>
                              </p>
                            </div>
                          ))
                        }
                      </div>
                    )}
                  </div>
                );
              }
            })}
          </div>

          <div>
            {productInfoData?.admin && <ul>Debug values {adminDebugData}</ul>}
          </div>

          <div
            className={`carousel-image-full-screen ${isOpen ? "is-full-screen" : ""
              }`}
          >
            <div className="carousel-image-full-screen__header">
              <Button
                className="vca-btn vca-btn--secondary-reverse"
                variant="outlined"
                onClick={() => closeImageFullScreen()}
              >
                {"Close"}
              </Button>
            </div>
            <div className="carousel-image-full-screen__image">
              {isOpen && productInfoData?.image?.length && (
                <ZoomImage
                  imgSrc={awsRepoImg(
                    productInfoData?.cluster_hash,
                    productInfoData?.image[slideNumber]?.path
                  )}
                />
              )}
            </div>
          </div>
        </div>
      )}

      {!productInfoData && (
        <div className="page-view-category__no-product">
          <h3 className="heading-3 center">This lot hasn't been found</h3>
        </div>
      )}
    </div>
  );
};

export default ViewProduct;
