import { isBoolean } from "helpers/utils";
import { MultiSelectRecord } from "models/Filters.interface";
import { getMarketPlacesListRecord } from "models/Store/MarketPlaces/MarketPlaceList";
import { ProductStoreFilterCheckbox } from "models/Store/ProductStore.interface";
import { getCreationTypesListRecord } from "./Jewels/CreationTypesList";
import { getClarityListRecord } from "./Jewels/Stones/ClarityList";
import { getColorGradesListRecord } from "./Jewels/Stones/ColorGradeList";
import { getAllAuctionsListRecord } from "./MarketPlaces/AuctionsLists";
import { getStatusListRecord } from "./StatusList";
import { getCategoriesListRecord } from "./Jewels/CategoriesList";


const mapProductFilterCheckboxStore = (
  data: ProductStoreFilterCheckbox,
  list: MultiSelectRecord,
  defaultValue: boolean | undefined): ProductStoreFilterCheckbox => {
  const productsMapped: ProductStoreFilterCheckbox =
    Object.values(list)
      .map(product => product.paramName)
      .reduce((acc: ProductStoreFilterCheckbox, currentValue: string) => {
        acc[currentValue] = isBoolean(data?.[currentValue]) ? data[currentValue] : defaultValue;
        return acc;
      }, {} as ProductStoreFilterCheckbox);

  return productsMapped;
}

// TODO dans l'idéal, passer la permission ici à la place de true. Mais pas nécessairepour que ca fonctionne
export const mapAuctionsStore = (data: ProductStoreFilterCheckbox, defaultValue: boolean | undefined = true):ProductStoreFilterCheckbox =>
  mapProductFilterCheckboxStore(data, getAllAuctionsListRecord(true), defaultValue)

export const mapMarketplacesStore = (data: ProductStoreFilterCheckbox, defaultValue: boolean | undefined = undefined):ProductStoreFilterCheckbox =>
  mapProductFilterCheckboxStore(data, getMarketPlacesListRecord(), defaultValue)

export const mapCategoriesStore = (data: ProductStoreFilterCheckbox, defaultValue: boolean | undefined = undefined): ProductStoreFilterCheckbox =>
  mapProductFilterCheckboxStore(data, getCategoriesListRecord(), defaultValue)

export const mapCreationTypesStore = (data: ProductStoreFilterCheckbox, defaultValue: boolean | undefined = undefined): ProductStoreFilterCheckbox =>
  mapProductFilterCheckboxStore(data, getCreationTypesListRecord(), defaultValue)

export const mapStatusStore = (data: ProductStoreFilterCheckbox, defaultValue: boolean | undefined = undefined):ProductStoreFilterCheckbox =>
  mapProductFilterCheckboxStore(data, getStatusListRecord(), defaultValue)

export const mapColorGradeStore = (data: ProductStoreFilterCheckbox, defaultValue: boolean | undefined = undefined):ProductStoreFilterCheckbox =>
  mapProductFilterCheckboxStore(data, getColorGradesListRecord(), defaultValue)

export const mapClarityStore = (data: ProductStoreFilterCheckbox, defaultValue: boolean | undefined = undefined):ProductStoreFilterCheckbox =>
  mapProductFilterCheckboxStore(data, getClarityListRecord(), defaultValue)