import { CurrenciesValues } from "models/Store/Jewels/Currency.interface";

interface Currency {
  label: string;
  value: string;
}

export const mainCurrencies: Record<string, Currency> = {
  [CurrenciesValues.EUR]: { label: "EUR", value: CurrenciesValues.EUR },
  [CurrenciesValues.USD]: { label: "USD", value: CurrenciesValues.USD },
}

export const getDefaultCurrency = (isVanCleef?: boolean): string => {
  const defaultCurrency = isVanCleef ? mainCurrencies[CurrenciesValues.EUR].value : mainCurrencies[CurrenciesValues.USD].value;
  return localStorage.getItem("vanCleef_main_currency") || defaultCurrency;
};

export const getCurrencyBase = (isCurrencyEur: boolean) => isCurrencyEur ? mainCurrencies[CurrenciesValues.EUR].label : mainCurrencies[CurrenciesValues.USD].label;