import { getMarketPlacesListRecord } from "models/Store/MarketPlaces/MarketPlaceList";
import { ProductStoreSearch, ProductStoreTotalState } from "models/Store/ProductStore.interface";
import { getProductStoreSearchMapper } from "models/Store/ProductStoreMapper";
import { all, put, select, takeLatest } from "redux-saga/effects";
import { dateManufactureRequestParams, dateRequestParams } from "store/mapper/requests/DateRequestMapper";
import { priceRequestParams } from "store/mapper/requests/PriceRequestMapper";
import { mapRequestParam, mapStoreCheckboxRequestParams } from "store/mapper/requests/ProductRequestMapper";
import { stoneRequestParams } from "store/mapper/requests/StoneRequestMapper";
import { RequestParamName } from "store/models/Request.enum";
import HTTPClient from "../../httpClient/dataClient";
import { handleRequest } from "../helpers/sagasHelpers";
import {
  GET_PRINT_PRODUCTS,
  GET_PRODUCT_INFO,
  GET_SEARCH_PRODUCTS_LIST,
  getPrintProducts,
  getProductInfo,
  getSearchProductsList,
  saveProductsList,
  saveProductsRequestsHistory,
  saveSearchFormData,
} from "./ProductActions";
import { currencySelector, productsRequestsHistorySelector, sortBySelector } from "./ProductSelectors";
import { getCreationTypesListRecord } from "models/Store/Jewels/CreationTypesList";
import { getAllAuctionsListRecord } from "models/Store/MarketPlaces/AuctionsLists";
import { getStatusListRecord } from "models/Store/StatusList";
import { getCategoriesListRecord } from "models/Store/Jewels/CategoriesList";
import { StoreAction } from "models/Store/Store.interface";
import { JewelResultsDto } from "models/Dto/JewelsDto.interface";
import { URLS_PARAMS_API } from "httpClient/urls.enum";
import { userSelector } from "store/application/ApplicationSelectors";
import { PermissionsList } from "providers/AuthContext";

function createQuery(productStoreSearch: ProductStoreTotalState, selectedSortBy: any, selectedCurrency: any, permissionList: PermissionsList): string {
  const {
    limit,
    searchFormData: {
      keyword,
      search_mode,
      price,
      stone,
      date,
      date_manufacture,
      page,
      sort_by,
      currency,
      status,
      auctions,
      marketplaces,
      categories,
      creationTypes,
      brands
    }
  } = productStoreSearch;
  console.log('createQuery', productStoreSearch)

  let requestParamsUrl = '';

  //page
  requestParamsUrl += page ? `${RequestParamName.PAGE}=${page}&` : `${RequestParamName.PAGE}=1&`;

  //limit
  requestParamsUrl += limit ? `${RequestParamName.LIMIT}=${limit}&` : `${RequestParamName.LIMIT}=30&`

  //Keyword
  requestParamsUrl += keyword ? `${RequestParamName.KEYWORD}=${encodeURIComponent(keyword)}&` : ``;

  //Strickt Keyword
  const strictKeywordMap = search_mode ? 1 : 2;
  requestParamsUrl += keyword && strictKeywordMap ? `${RequestParamName.SEARCH_MODE}=${strictKeywordMap}&` : ``;

  // Price
  requestParamsUrl += priceRequestParams(price);

  //Date
  requestParamsUrl += dateRequestParams(date);
  requestParamsUrl += dateManufactureRequestParams(date_manufacture);


  // Sort By
  let actualSort_by = sort_by ?? selectedSortBy;
  // TODO remove when BDD is ok
  if (actualSort_by === '-') {
    actualSort_by = '';
  } else {
    requestParamsUrl += actualSort_by;
  }

  //Currency
  requestParamsUrl += currency ? `${RequestParamName.CURRENCY}=${currency}&` : `${RequestParamName.CURRENCY}=${selectedCurrency}&`;

  //Stones
  requestParamsUrl += stoneRequestParams(stone);

  // Status (sold)
  requestParamsUrl += mapStoreCheckboxRequestParams(status, getStatusListRecord(), RequestParamName.SOLD);

  // Source 
  requestParamsUrl += mapStoreCheckboxRequestParams({ ...marketplaces, ...auctions }, { ...getAllAuctionsListRecord(permissionList.hasOtherAuctionsPermission), ...getMarketPlacesListRecord() }, RequestParamName.SOURCE)

  // Creation type
  requestParamsUrl += mapStoreCheckboxRequestParams(creationTypes, getCreationTypesListRecord(), RequestParamName.CREATION_TYPES);
  
  requestParamsUrl += mapStoreCheckboxRequestParams(categories, getCategoriesListRecord(), RequestParamName.CATEGORIES);
  
  // Brands
  requestParamsUrl += brands ? mapRequestParam(RequestParamName.BRANDS, brands.toString()) : '';
  
  return requestParamsUrl;
}

function* handleGetProductInfoRequest({ productId }: any) {
  try {
    yield* handleRequest(
      getProductInfo,
      HTTPClient.createClient().get(`/${URLS_PARAMS_API.JEWLRY_LIST}/${productId}`)
    );
  } catch (error) {
    return { Error: true, result: error };
  }
}

function handleGetProductInfoSuccess(response: StoreAction<JewelResultsDto>): void {
  console.info(response)
}

function* handleGetSearchProductsListRequest(productStoreSearch: ProductStoreSearch): Generator<any> {
  const selectedSortBy: any = yield select(sortBySelector);
  const selectedCurrency: any = yield select(currencySelector);
  const {specificPermissions}: any = yield select (userSelector);

  const productStoreState: ProductStoreTotalState = {
    limit: undefined,
    searchFormData: productStoreSearch
  }

  console.log(productStoreSearch)

  const query: string = createQuery(productStoreState, selectedSortBy, selectedCurrency, specificPermissions);
  const queryUrl = `/${URLS_PARAMS_API.JEWLRY_LIST}?${query}`;
  yield put(
    saveSearchFormData({
      data: getProductStoreSearchMapper(productStoreSearch, undefined, selectedSortBy),
    })
  );
  try {
    const productsRequestHistory: any = yield select(productsRequestsHistorySelector);
    if(productsRequestHistory.size > 0 && productsRequestHistory.get(queryUrl)){
      yield put(
        saveProductsList({payload: productsRequestHistory.get(queryUrl)})
      )
    } else {
      yield* handleRequest(
        getSearchProductsList,
        HTTPClient.createClient().get(queryUrl)
      );
    }
  } catch (error) {
    return { Error: true, result: error };
  }
}

function* handleGetSearchProductsListSuccess(response: StoreAction<JewelResultsDto>): Generator<any> {
  console.info(response)
  yield put(
    saveProductsRequestsHistory({payload: response.payload})
  );
}

function* handleGetPrintProductsRequest(productStoreSearch: ProductStoreSearch): Generator<any> {
  try {
    const selectedSortBy = yield select(sortBySelector);
    const selectedCurrency = yield select(currencySelector);
    const {specificPermissions}: any = yield select (userSelector);

    const productStoreState: ProductStoreTotalState = {
      limit: undefined,
      searchFormData: productStoreSearch
    }
    const query = createQuery(productStoreState, selectedSortBy, selectedCurrency, specificPermissions);
    yield* handleRequest(
      getPrintProducts,
      HTTPClient.createClient().get(`/${URLS_PARAMS_API.JEWLRY_LIST}?${query}download=true`)
    );
  } catch (error) {
    return { Error: true, result: error };
  }
}




function handleRequestSuccessPrint(response: any) {
  const blob = new Blob([response.payload], { type: 'text/csv;charset=utf-8;' });
  const href = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', 'results.csv'); //or any other extension
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function handleRequestFailure(response: any) {
  console.info(response);
}

export default function* () {
  yield all([
    takeLatest(GET_PRODUCT_INFO.REQUEST, handleGetProductInfoRequest),
    takeLatest(GET_PRODUCT_INFO.SUCCESS, handleGetProductInfoSuccess),
    takeLatest(GET_PRODUCT_INFO.FAILURE, handleRequestFailure),
    takeLatest(
      GET_SEARCH_PRODUCTS_LIST.REQUEST,
      handleGetSearchProductsListRequest
    ),
    takeLatest(GET_SEARCH_PRODUCTS_LIST.SUCCESS, handleGetSearchProductsListSuccess),
    takeLatest(GET_SEARCH_PRODUCTS_LIST.FAILURE, handleRequestFailure),
    takeLatest(GET_PRINT_PRODUCTS.REQUEST, handleGetPrintProductsRequest),
    takeLatest(GET_PRINT_PRODUCTS.SUCCESS, handleRequestSuccessPrint),
    takeLatest(GET_PRINT_PRODUCTS.FAILURE, handleRequestFailure),
  ]);
}
