import { CurrenciesValues } from "models/Store/Jewels/Currency.interface";
import { mainCurrencies } from "utils/currency";

class StringDefaulter {


  static defaultEmpty(stringBase: any, stringDefault = '-', stringNew:any = null){
    if (!stringBase) return stringDefault;
    
    const newString = stringNew ? stringNew : stringBase;
    return newString;
  }
  
  static defaultPrice(priceBase: any, currencyBase: any, stringDefault = '-', stringNew: any = null){
    const priceBaseRounded = Math.round(priceBase)
    if(!priceBaseRounded) return stringDefault;

    const newString = stringNew ? stringNew : priceBaseRounded.toLocaleString('en-US') + ' ' + this.prototype._displayCurrency(currencyBase);
    return newString;
  }

  static defaultDate(date: any){
    return new Date(date).toLocaleString('en-US', ({ year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' }));
  }


  _displayCurrency(currency: any){
    if(!currency) return mainCurrencies[CurrenciesValues.EUR].label;

    // switch (currency) {
    //   case 'EUR':
    //     return '€'
    //   case 'USD':
    //     return '$'
    //   default:
    //     return currency
    // }

    return currency
    
  }

}

export default StringDefaulter;