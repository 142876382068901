import { PriceBlockProps } from "components/viewProduct/PriceBlock/PriceBlock";
import { JewelDto } from "models/Dto/JewelsDto.interface";
import { isJewelStatusForSale, isJewelStatusNotSold, isJewelStatusSold, isMarketplace } from "./jewels.utils";


export const createPriceBlocksInfoArray = (jewel: JewelDto, isCurrencyEur: boolean): Array<PriceBlockProps> => {
  const pricesBlockArray = [];
  if (jewel.estimate_low || jewel.estimate_high) {
    pricesBlockArray.push({
      jewel,
      title: 'Estimate',
      valueCurrency: isCurrencyEur ? jewel?.estimate_low_eur : jewel?.estimate_low_usd,
      valueCurrencySecondary: isCurrencyEur ? jewel?.estimate_high_eur : jewel?.estimate_high_usd,
      valueSource: jewel?.estimate_low,
      valueSourceSecondary: jewel?.estimate_high,
      alternativeText: "Not Available",
    })
  }

  if(isJewelStatusSold(jewel.status) || isJewelStatusNotSold(jewel.status)){
    pricesBlockArray.push({
      jewel,
      title: 'Final Sale Price',
      valueSource: jewel?.price,
      valueCurrency: isCurrencyEur ? jewel?.price_eur : jewel?.price_usd,
      alternativeText: "Not Available",
      bold: true,
    })
  }
  
  if(isJewelStatusForSale(jewel.status)){
    pricesBlockArray.push({
      jewel,
      title: 'Current Price for Sale',
      valueSource: jewel?.current_selling_price,
      valueCurrency: isCurrencyEur ? jewel?.current_selling_price_eur : jewel?.current_selling_price_usd,
      alternativeText: "Not Available"
    })
  }
  
  if(isMarketplace(jewel.source_type) && jewel.previous_price){
    pricesBlockArray.push({
      jewel,
      title: 'Previous Price for Sale',
      valueSource: jewel?.previous_price,
      valueCurrency: isCurrencyEur ? jewel?.previous_price_eur : jewel?.previous_price,
      alternativeText: "Not Available"
    })
  }
  
  if(isMarketplace(jewel.source_type) && jewel?.estimated_retail_price){
    pricesBlockArray.push({
      jewel,
      title: 'Estimated retail price',
      valueSource: jewel?.estimated_retail_price,
      valueCurrency: isCurrencyEur ? jewel?.estimated_retail_price_eur : jewel?.estimated_retail_price_usd,
      alternativeText: "Not Available"
    })
  }

  return pricesBlockArray;
}