import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React, { ReactNode } from "react";


interface AccordionWrapperProps {
  title: string;
  children: ReactNode;
  amountBoxChecked?: number
  isSubAccordion?: boolean
}


const AccordionWrapper: React.FC<AccordionWrapperProps> = ({ children, title, amountBoxChecked, isSubAccordion }) => {
  const AccordionAmountComponent = amountBoxChecked > 0 && (
    <span className="accordion-title-amount">({amountBoxChecked})</span>
  )
  return (
    <>
      <Accordion className={isSubAccordion ? 'sub-accordion' : ''}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
        >
          <h3 className="accordion-title"><span>{title}</span>{AccordionAmountComponent}</h3>
        </AccordionSummary>
        <AccordionDetails>
          {children}
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default AccordionWrapper;


